<template>
  <nav
    ref="scrollArea"
    class="overflow-auto p-5 px-8 pt-0 xs:pr-20"
  >
    <ul>
      <li
        v-for="parentLink in activeParentLinks"
        :key="parentLink.uid"
        :class="styles.topLevelLink"
      >
        <button
          class="grow text-left leading-tight"
          @click.prevent="onNavigate(parentLink)"
        >
          {{ parentLink.title }}
        </button>

        <NuxtLink
          v-if="currentCategory"
          class="flex items-center gap-1 text-sm font-bold"
          :to="currentCategory.link"
          @click="emit('navigate', currentCategory)"
        >
          {{ labels.allCategories }}
          <UiIcon
            name="arrow"
            :width="16"
            :height="16"
            class="pt-0.5"
          />
        </NuxtLink>
      </li>
    </ul>

    <ul
      v-if="currentCategory?.children"
      class="flex flex-col gap-2 pt-2.5"
    >
      <li
        v-for="child in currentCategory.children"
        :key="child.uid"
      >
        <a
          :href="child.link"
          class="flex items-center justify-between py-2.5 text-sm font-bold text-blue"
          @click.prevent="onNavigate(child)"
        >
          {{ child.title }}
          <UiIcon
            v-if="child?.children_count"
            name="arrow"
            :width="16"
            :height="16"
            class="pt-0.5"
          />
        </a>
      </li>
    </ul>

    <div
      v-else-if="currentCategory?.children_count > 0"
      class="flex flex-col gap-5 pt-4"
    >
      <Skeletor
        v-for="item in currentCategory.children_count"
        :key="item"
        as="div"
        :height="28"
        class="w-full"
      />
    </div>

    <slot name="footer" />
  </nav>
</template>

<script lang="ts" setup>
import { Skeletor } from 'vue-skeletor'
import { computed, ref } from 'vue'
import UiIcon from '../../../UiIcon/UiIcon.vue'
import type { UiNavLink } from '../../UiNav.types'

type UiNavCategoryLink = UiNavLink<string>

interface UiNavMobile {
  /**
   * Array of links
   */
  links: UiNavCategoryLink[]
  /**
   * Array of active breadcrumbs of links
   * Used as v-model
   */
  active: UiNavCategoryLink[] // TODO: merge with breadcrumbs
  /**
   * Array of active breadcrumbs of links
   * Used as v-model
   */
  breadcrumbs: UiNavCategoryLink[]
  labels: {
    allCategories: string
  }
}

const props = withDefaults(defineProps<UiNavMobile>(), {
  links: () => [],
  active: () => [],
  labels: () => ({
    allCategories: 'all',
  }),
})

const emit = defineEmits<{
  /** notify about new active link */
  (e: 'update:active', link: UiNavLink[]): void
  /** parent component should update navigation for current link */
  (e: 'update:navigation', link: UiNavLink): void
  /** use to perform navigation */
  (e: 'navigate', link: UiNavCategoryLink): void
  /** update breadcrumbs structure */
  (e: 'update:breadcrumbs', link: UiNavCategoryLink[]): void
}>()

const scrollArea = ref(null)

/**
 * from oldest to newest nest in categories
 */
const navLinkBreadcrumbs = computed<UiNavCategoryLink[]>({
  get: () => props.breadcrumbs,
  set: v => emit('update:breadcrumbs', v),
})
const currentCategory = computed(() => {
  return navLinkBreadcrumbs.value?.at(-1)
})

function getBreadcrumbByUid(uid: string) {
  return navLinkBreadcrumbs.value.find(breadcrumb => breadcrumb?.uid === uid)
}

const activeParentLinks = computed(() => {
  if (navLinkBreadcrumbs.value.length === 0) {
    return props.links
  }

  if (currentCategory.value) {
    return [currentCategory.value]
  }

  return props.links.filter(link => getBreadcrumbByUid(link.uid))
})

function onNavigate(link: UiNavCategoryLink) {
  const hasChildren = link.children_count || link.children?.length > 0

  if (!hasChildren || !link.uid) {
    return emit('navigate', link)
  }

  if (getBreadcrumbByUid(link?.uid)) {
    return
  }

  // add link to breadcrumbs stack and request data
  navLinkBreadcrumbs.value.push(link)
  emit('update:breadcrumbs', navLinkBreadcrumbs.value)

  // if link has children but children are not fetched yet
  // call update event to fetch childrens
  if (link.children_count && !link.children) {
    emit('update:active', [link])
    return emit('update:navigation', { ...link })
  }

  // if link has fechted children already update active array
  if (link.children_count && link.children) {
    emit('update:active', [link])
  }
}

const styles = {
  topLevelLink: 'block border-b border-b-blue-100 py-5 text-lg font-bold text-primary flex items-center justify-between',
}
</script>
